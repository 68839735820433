const imgurl={
    "UNSDCF_doc1":require('../Assets/UNSDCF_doc1.png'),
    "UNSDCF_pdf1":require('../Assets/UNSDCF_pdf1.png'),
    "UNSDCF_pdf2":require('../Assets/UNSDCF_pdf2.png'),
    "UNSDCF_ppt1":require('../Assets/UNSDCF_ppt1.png'),
    "Management_Response_to_CPE_xls1":require('../Assets/Management_Response_to_CPE_xls1.png'),
    "Management_Response_to_CPE_xls2":require('../Assets/Management_Response_to_CPE_xls2.png'),
    "sis_guidance_doc1":require('../Assets/sis_guidance_doc1.png'),
    "sis_guidance_doc2":require('../Assets/sis_guidance_doc2.png'),
    "sis_guidance_doc3":require('../Assets/sis_guidance_doc3.png'),
    "sis_guidance_pdf1":require('../Assets/sis_guidance_pdf1.png'),
    "sis_guidance_pdf2":require('../Assets/sis_guidance_pdf2.png'),
    "country_prog_doc1":require('../Assets/country_prog_doc1.png'),
    "country_prog_doc2":require('../Assets/country_prog_doc2.png'),
    "country_prog_doc3":require('../Assets/country_prog_doc3.png'),
    "country_prog_doc4":require('../Assets/country_prog_doc4.png'),
    "country_prog_pdf1":require('../Assets/country_prog_pdf1.png'),
    "countryprog_cpap":require('../Assets/countryprog_cpap.png'),
    "countryprog_cppdf1":require('../Assets/countryprog_cppdf1.png'),
    "countryprog_cppdf2":require('../Assets/countryprog_cppdf2.png'),
    "countryprog_cppdf3":require('../Assets/countryprog_cppdf3.png'),
    "countryprog_cpxl1":require('../Assets/countryprog_cpxl1.png'),
    "countryprog_cpxl2":require('../Assets/countryprog_cpxl2.png'),
    "countryprog_cpxl3":require('../Assets/countryprog_cpxl3.png'),
    "countryprog_cpxl4":require('../Assets/countryprog_cpxl4.png'),
    "countryprog_hr1":require('../Assets/countryprog_hr1.png'),
    "countryprog_hr2":require('../Assets/countryprog_hr2.png'),
    "countryprog_toc1":require('../Assets/countryprog_toc1.png'),
    "countryprog_toc2":require('../Assets/countryprog_toc2.png'),
    "countryprog_toc3":require('../Assets/countryprog_toc3.png'),
    "countryprog_toc4":require('../Assets/countryprog_toc4.png'),
    "help_section":require('../Assets/help_section.png')
}

export default imgurl